/* This example requires Tailwind CSS v2.0+ */
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import React from "react";
import { Link } from "react-router-dom";
import { FixedSizeList } from "react-window";
import { db } from "../firebase.js";

var classNames = require("classnames");

class IdentityRow extends React.Component {
  render() {
    let identity = this.props.data[0][this.props.index];
    return (
      <Link to={"/app/people/" + identity.id}>
        <div
          className={
            this.props.data[1]
              ? classNames(
                  this.props.data[1].includes(identity.ref.id)
                    ? "bg-white"
                    : "bg-red-50"
                )
              : "bg-grey-50"
          }
          style={this.props.style}
        >
          <div className="px-2 py-1 sm:px-6 whitespace-nowrap">
            <div className="px-6 py-2 whitespace-nowrap">
              <div className="flex items-center">
                <div className="flex-shrink-0 h-8 w-8">
                  <img
                    className="h-8 w-8 rounded-full object-cover"
                    src={
                      "https://storage.googleapis.com/uplink-services-roster-photos/" +
                      identity.data().photo
                    }
                    alt={
                      identity.data().firstName + " " + identity.data().lastName
                    }
                  />
                </div>
                <div className="ml-4">
                  <div className="text-sm font-medium text-gray-900">
                    {identity.data().firstName + " " + identity.data().lastName}
                  </div>
                </div>
                <div className="ml-4">
                  <div className="text-sm text-gray-500">
                    {(() => {
                      switch (identity.data().grade) {
                        case 9:
                          return "Freshman";
                        case 10:
                          return "Sophomore";
                        case 11:
                          return "Junior";
                        case 12:
                          return "Senior";
                        default:
                          return "Unknown";
                      }
                    })()}
                    {/* , ID {identity.data().studentNumber} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

class IdentitiesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryResult: null,
      cardMap: null,
      searchQuery: "",
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData = () => {
    const q = query(
      collection(db, "identities"),
      orderBy("firstName", "asc"),
      limit(2000)
    );

    getDocs(q).then((docs) => {
      this.setState({ queryResult: docs.docs });
      // for each doc, search for matching cards

      let cardQuery = query(collection(db, "cards"));
      getDocs(cardQuery).then((cards) => {
        let cardMap = [];
        cards.docs.forEach((card) => {
          cardMap.push(card.data().identity.id);
        });

        this.setState({ cardMap: cardMap });
      });
    });
  };

  render() {
    let filteredQueryResult = null;
    if (this.state.queryResult !== null) {
      filteredQueryResult = this.state.queryResult.filter((element) => {
        let matchesSearch = false;
        element.data().fullName =
          element.data().firstName + " " + element.data().lastName;
        let propertiesToSearch = [
          ...Object.values(element.data),
          element.data().firstName + " " + element.data().lastName,
        ];
        propertiesToSearch.forEach((property) => {
          if (property != null) {
            if (
              property
                .toString()
                .toLowerCase()
                .trim()
                .includes(this.state.searchQuery.toLowerCase().trim())
            ) {
              matchesSearch = true;
            }
          }
        });
        return matchesSearch;
      });
    }

    return (
      <div className="flex flex-col mx-2 w-full">
        <div className="my-8 lg:mt-0 place-content-between flex flex-row">
          <div>
            <label
              htmlFor="search"
              className="block text-sm font-medium text-gray-700"
            >
              Quick search
            </label>
            <div className="mt-1 relative flex items-center">
              <input
                type="text"
                name="search"
                id="search"
                spellCheck="false"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                onChange={(event) => {
                  this.setState({ searchQuery: event.target.value });
                }}
              />
              {/*               
              <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                <kbd className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
                  ⌘K
                </kbd>
              </div> */}
            </div>
          </div>
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {filteredQueryResult && filteredQueryResult.length > 0 ? (
                <FixedSizeList
                  itemData={[filteredQueryResult, this.state.cardMap]}
                  height={600}
                  itemCount={filteredQueryResult.length}
                  itemSize={56}
                  className={"sm:rounded-lg"}
                >
                  {IdentityRow}
                </FixedSizeList>
              ) : (
                <div className="text-center my-3">
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No users found
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Try searching for something else.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IdentitiesTable;
