/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import React, { Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import UplinkIcon400 from "../assets/uplink-icon-400.svg";
import { auth } from "../firebase";
const navigation = [
  { name: "Logbooks", href: "/app/logbooks" },
  { name: "People", href: "/app/people" },
  { name: "Sensors", href: "/app/sensors" },
];
const userNavigation = [
  // { name: "Settings", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class AppShell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: null,
    };
  }
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        auth.currentUser.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.role) {
            this.setState({ role: idTokenResult.claims.role });
          } else {
            this.setState({ role: idTokenResult.claims.role });
          }
        });
      }
    });
  }

  render() {
    return (
      <>
        {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
        <div className="min-h-screen dark:bg-black">
          <div className="bg-indigo-600 pb-32">
            <Disclosure
              as="nav"
              className="bg-indigo-600 border-b border-indigo-300 border-opacity-25 lg:border-none"
            >
              {({ open }) => (
                <>
                  <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                    <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                      <div className="px-2 flex items-center lg:px-0">
                        <div className="flex-shrink-0">
                          <Link to="/">
                            <img
                              className="block h-8 w-8 "
                              src={UplinkIcon400}
                              alt="Uplink"
                            />
                          </Link>
                        </div>
                        <div className="hidden lg:block lg:ml-10">
                          <div className="flex space-x-4">
                            {navigation.map((item) => (
                              <NavLink
                                key={item.name}
                                to={item.href}
                                className={(navData) =>
                                  classNames(
                                    navData.isActive
                                      ? "bg-indigo-700 text-white"
                                      : "text-white hover:bg-indigo-500 hover:bg-opacity-75",
                                    "rounded-md py-2 px-3 text-sm font-medium"
                                  )
                                }
                              >
                                {item.name}
                              </NavLink>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
                        {/* <div className="max-w-lg w-full lg:max-w-xs">
                          <label htmlFor="search" className="sr-only">
                            Search
                          </label>
                          <div className="relative text-gray-400 focus-within:text-gray-600">
                            <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                              <SearchIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </div>
                            <input
                              id="search"
                              className="block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
                              placeholder="Search"
                              type="search"
                              name="search"
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className="flex lg:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="bg-indigo-600 p-2 rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <MenuIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                      <div className="hidden lg:block lg:ml-4">
                        <div className="flex items-center">
                          <button
                            type="button"
                            className="bg-indigo-600 flex-shrink-0 rounded-full p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
                          >
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                          </button>

                          {/* Profile dropdown */}
                          <Menu
                            as="div"
                            className="ml-3 relative flex-shrink-0"
                          >
                            <div>
                              <Menu.Button className="bg-indigo-600 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                                <span className="sr-only">Open user menu</span>
                                <img
                                  className="rounded-full h-8 w-8 "
                                  src={auth.currentUser.photoURL}
                                  alt=""
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <p className="block pt-2 px-4 text-xs text-gray-700">
                                  {auth.currentUser.displayName}
                                </p>
                                <p className="block pb-2 px-4 text-xs text-gray-700">
                                  {auth.currentUser.email}
                                </p>

                                {this.state.role ? (
                                  <p className="block pb-2 px-4 text-xs text-gray-700">
                                    You're an {this.state.role}.
                                  </p>
                                ) : null}
                                {userNavigation.map((item) => (
                                  <NavLink
                                    key={item.name}
                                    to={item.href}
                                    className={(navData) =>
                                      classNames(
                                        navData.isActive ? "bg-gray-100" : "",
                                        "block py-2 px-4 text-sm text-gray-700"
                                      )
                                    }
                                  >
                                    {item.name}
                                  </NavLink>
                                ))}
                                <Menu.Item key="logout">
                                  {({ active }) => (
                                    <button
                                      onClick={() => {
                                        auth.signOut();
                                        window.location.reload();
                                      }}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block py-2 px-4 text-sm text-gray-700 cursor-pointer w-full text-left"
                                      )}
                                    >
                                      Sign out
                                    </button>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="lg:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1">
                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "bg-indigo-700 text-white"
                              : "text-white hover:bg-indigo-500 hover:bg-opacity-75",
                            "block rounded-md py-2 px-3 text-base font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                    <div className="pt-4 pb-3 border-t border-indigo-700">
                      <div className="px-5 flex items-center">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-full h-10 w-10"
                            src={auth.currentUser.photoURL}
                            alt=""
                          />
                        </div>
                        <div className="ml-3">
                          <div className="text-base font-medium text-white">
                            {auth.currentUser.displayName}
                          </div>
                          <div className="text-sm font-medium text-indigo-300">
                            {auth.currentUser.email}
                          </div>

                          <div className="text-sm font-medium text-indigo-300">
                            {this.state.role ? (
                              <div className="text-sm font-medium text-indigo-300">
                                {" "}
                                You're an {this.state.role}.
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <button
                          type="button"
                          className="ml-auto bg-indigo-600 flex-shrink-0 rounded-full p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
                        >
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <div className="mt-3 px-2 space-y-1">
                        {userNavigation.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <header className="py-10">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-white">
                  {this.props.header}
                </h1>
              </div>
            </header>
          </div>

          <main className="-mt-32">
            <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
              <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 flex flex-row">
                {this.props.children}
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}

export default AppShell;
