import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon, TrashIcon } from "@heroicons/react/solid";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { default as React, Fragment } from "react";
import { Link } from "react-router-dom";
import { FixedSizeList } from "react-window";
import { db } from "../firebase.js";

class LogbookRow extends React.Component {
  openParentDeleteModal() {
    this.props.data[1].openDeleteModal(this.props.data[0][this.props.index]);
  }
  render() {
    let logbook = this.props.data[0][this.props.index];
    return (
      <div className="border-b border-gray-200" style={this.props.style}>
        <div className="px-6 py-2 whitespace-nowrap">
          <div className="flex items-center justify-between">
            <div>
              <div className="text-lg font-medium text-gray-900">
                <Link to={`/app/logbooks/${logbook.id}`}>
                  {logbook.data.name}
                </Link>
              </div>
            </div>
            <TrashIcon
              className="h-6 w-6 text-gray-500 cursor-pointer"
              onClick={this.openParentDeleteModal.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
}

class LogbooksTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryResult: null,
      deleteModalOpen: false,
      deleteModalItem: null,
      deleteModalError: null,
      deleteModalProcessing: false,
    };
  }

  componentDidMount() {
    this.refreshData();
  }
  openDeleteModal = (item) => {
    this.setState({
      deleteModalOpen: true,
      deleteModalItem: item,
      deleteModalError: "Yes",
      deleteModalProcessing: false,
    });
  };

  closeDeleteModal() {
    this.setState({
      deleteModalOpen: false,
    });
  }
  refreshData = () => {
    const q = query(
      collection(db, "logbooks"),
      orderBy("name", "desc"),
      limit(2000)
    );

    var resolvedData = [];

    getDocs(q).then((logbooks) => {
      logbooks.docs.forEach((logbook) => {
        resolvedData.push({
          ref: logbook.ref,
          id: logbook.id,
          data: { ...logbook.data() },
        });
      });

      Promise.all(resolvedData).then((values) => {
        this.setState({
          queryResult: values,
        });
      });
    });
  };

  render() {
    return (
      <div className="flex flex-col mx-2 w-full">
        <Transition appear show={this.state.deleteModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={this.closeDeleteModal.bind(this)}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-sm" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Delete{" "}
                        {this.state.deleteModalItem
                          ? this.state.deleteModalItem.data.name
                          : "an unknown logbook"}
                        ?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Delete functionality isn't currently implemented, so
                          this won't do anything. Please get in touch with
                          Nathan.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                      onClick={this.closeDeleteModal.bind(this)}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={this.closeDeleteModal.bind(this)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            {this.state.queryResult ? (
              <FixedSizeList
                itemData={[this.state.queryResult, this]}
                height={200}
                itemCount={this.state.queryResult.length}
                itemSize={44}
              >
                {LogbookRow}
              </FixedSizeList>
            ) : (
              <div className="text-center my-3">
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  Loading logbooks...
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LogbooksTable;
