import { Combobox } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React from "react";
class PeopleCombobox extends React.Component {
  constructor() {
    super();
    this.state = {
      query: null,
      selectedPerson: null,
    };
  }

  render() {
    let filteredQueryResult = null;
    if (this.props.data !== null) {
      if (this.state.query) {
        filteredQueryResult = this.props.data.filter((element) => {
          let matchesSearch = false;
          element.data().fullName =
            element.data().firstName + " " + element.data().lastName;
          let propertiesToSearch = [
            ...Object.values(element.data),
            element.data().firstName + " " + element.data().lastName,
          ];
          propertiesToSearch.forEach((property) => {
            if (property != null) {
              if (
                property
                  .toString()
                  .toLowerCase()
                  .trim()
                  .includes(this.state.query.toLowerCase().trim())
              ) {
                matchesSearch = true;
              }
            }
          });
          return matchesSearch;
        });
      } else {
        filteredQueryResult = [];
      }

      filteredQueryResult = filteredQueryResult.slice(0, 4);
    }

    return (
      <Combobox
        as="div"
        value={this.state.selectedPerson}
        onChange={(person) => {
          this.setState({ selectedPerson: person });
          this.props.updateAddModalSelectedPerson(person);
        }}
        className={"mb-4"}
      >
        <Combobox.Label className="block text-base font-medium text-gray-900">
          Select a student
        </Combobox.Label>
        <div className="relative mt-1">
          <Combobox.Input
            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => this.setState({ query: event.target.value })}
            displayValue={(person) => {
              if (person) {
                return person.data().firstName + " " + person.data().lastName;
              } else {
                return "";
              }
            }}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredQueryResult.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredQueryResult.map((person) => (
                <Combobox.Option
                  key={person.id}
                  value={person}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex items-center">
                        <img
                          src={
                            "https://storage.googleapis.com/uplink-services-roster-photos/" +
                            person.data().photo
                          }
                          alt=""
                          className="h-6 w-6 flex-shrink-0 rounded-full object-cover"
                        />
                        <span
                          className={classNames(
                            "ml-3 truncate",
                            selected && "font-semibold"
                          )}
                        >
                          {person.data().firstName +
                            " " +
                            person.data().lastName}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    );
  }

  componentDidMount() {}
}

export default PeopleCombobox;
