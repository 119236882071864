import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { UserAddIcon, XCircleIcon, XIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { compareDesc } from "date-fns";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { db } from "../firebase.js";
import PeopleCombobox from "./PeopleCombobox.js";
TimeAgo.addDefaultLocale(en);

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logbook: null,
      queryResult: null,
      identityQueryResult: null,
      sourceName: null,
      addModalOpen: false,
      addModalIdentityData: null,
      addModalSelectedPerson: null,
      addModalSelectedType: null,
      addModalError: null,
      addModalProcessing: false,
    };
  }

  componentDidMount() {
    getDoc(doc(db, "logbooks", this.props.logbook)).then((doc) => {
      if (doc) {
        this.setState({
          logbook: doc,
        });
      }
      this.refreshData();
    });
  }

  openAddModal(data) {
    console.log("opening modal for " + data);
    this.setState({ addModalIdentityData: data });
    this.setState({ addModalOpen: true });
  }

  closeAddModal() {
    this.setState({
      addModalOpen: false,
      addModalIdentityData: null,
      addModalSelectedPerson: null,
      addModalSelectedType: null,
      addModalError: null,
      addModalProcessing: false,
    });
  }
  updateAddModalSelectedPerson(person) {
    this.setState({ addModalSelectedPerson: person });
  }
  updateAddModalSelectedType(type) {
    this.setState({ addModalSelectedType: type });
  }
  runAddModal() {
    if (
      this.state.addModalIdentityData &&
      this.state.addModalSelectedPerson &&
      this.state.addModalSelectedType
    ) {
      console.log(
        "Will link " +
          this.state.addModalIdentityData +
          " to " +
          this.state.addModalSelectedPerson.data().firstName +
          " " +
          this.state.addModalSelectedPerson.data().lastName +
          " as " +
          this.state.addModalSelectedType
      );

      this.setState({ addModalProcessing: true }, () => {
        setDoc(doc(db, "cards", this.state.addModalIdentityData), {
          identity: this.state.addModalSelectedPerson.ref,
          type: this.state.addModalSelectedType,
          issueDate: serverTimestamp(),
        }).then(() => {
          this.setState({ addModalProcessing: false });
          this.closeAddModal();
          this.refreshData();
        });
        this.closeAddModal();
      });
    } else {
      this.setState({ addModalError: "Please fill out all fields" });
      return false;
    }
  }

  refreshData = () => {
    const q = query(
      collection(db, "events"),
      where("logbook", "==", this.state.logbook.ref)
    );

    onSnapshot(q, (querySnapshot) => {
      var resolvedData = [];
      querySnapshot.docs.forEach((eventDoc) => {
        resolvedData.push(
          getDoc(eventDoc.data().source).then((sourceDoc) => {
            return getDoc(doc(db, "cards", eventDoc.data().data))
              .then((cardDoc) => {
                if (cardDoc.data()) {
                  return getDoc(cardDoc.data().identity).then((identityDoc) => {
                    return identityDoc;
                  });
                } else {
                  return null;
                }
              })
              .then((identityDoc) => {
                if (identityDoc !== null) {
                  return {
                    ref: eventDoc.ref,
                    id: eventDoc.id,
                    data: { ...eventDoc.data() },
                    source: { id: sourceDoc.id, data: { ...sourceDoc.data() } },
                    identity: {
                      id: identityDoc.id,
                      data: { ...identityDoc.data() },
                    },
                  };
                } else {
                  return {
                    ref: eventDoc.ref,
                    id: eventDoc.id,
                    data: { ...eventDoc.data() },
                    source: { id: sourceDoc.id, data: { ...sourceDoc.data() } },
                    identity: {
                      id: -1,
                      data: { firstName: "Unenrolled", lastName: "student" },
                    },
                  };
                }
              });
          })
        );
      });

      Promise.all(resolvedData).then((values) => {
        this.setState({
          queryResult: values.sort((a, b) => {
            return compareDesc(
              a.data.timestamp.toDate(),
              b.data.timestamp.toDate()
            );
          }),
        });
      });
    });

    // then, get identities for add identity modal

    const identityQuery = query(
      collection(db, "identities"),
      orderBy("firstName", "asc"),
      limit(2000)
    );

    getDocs(identityQuery).then((docs) => {
      this.setState({ identityQueryResult: docs.docs });
    });
  };

  deleteEvent(doc) {
    deleteDoc(doc.ref).then(() => {
      this.refreshData();
    });
  }

  render() {
    let identityTypes = [
      { value: "wahs-badge", label: "West Ashley High School badge" },
      { value: "sticker", label: "Sticker" },
    ];
    return (
      <div className="flex flex-col mx-2 w-full">
        <Transition appear show={this.state.addModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={this.closeAddModal.bind(this)}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-sm" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  {/* <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Link an unenrolled student
                  </Dialog.Title>
                    */}
                  {this.state.addModalError ? (
                    <div className="rounded-md bg-red-50 p-4 mb-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-red-800">
                            {this.state.addModalError}
                          </p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                              onClick={() =>
                                this.setState({ addModalError: null })
                              }
                            >
                              <span className="sr-only">Dismiss</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <PeopleCombobox
                    data={this.state.identityQueryResult}
                    updateAddModalSelectedPerson={this.updateAddModalSelectedPerson.bind(
                      this
                    )}
                  />

                  <div className="my-6">
                    <label className="text-base font-medium text-gray-900">
                      Identity type
                    </label>
                    <p className="text-sm leading-5 text-gray-500">
                      What type of identity are you enrolling?
                    </p>
                    <RadioGroup
                      value={this.state.addModalSelectedType}
                      onChange={this.updateAddModalSelectedType.bind(this)}
                      className="my-2"
                    >
                      <RadioGroup.Label className="sr-only">
                        Server size
                      </RadioGroup.Label>
                      <div className="space-y-2">
                        {identityTypes.map((type) => (
                          <RadioGroup.Option
                            key={type.label}
                            value={type.value}
                            className={({ checked, active }) =>
                              classNames(
                                checked
                                  ? "border-transparent"
                                  : "border-gray-300",
                                active
                                  ? "border-indigo-500 ring-2 ring-indigo-500"
                                  : "",
                                "relative block bg-white border rounded-lg shadow-sm px-6 py-3 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <>
                                <div className="flex items-center">
                                  <div className="text-sm">
                                    <RadioGroup.Label
                                      as="p"
                                      className="font-medium text-gray-900"
                                    >
                                      {type.label}
                                    </RadioGroup.Label>
                                  </div>
                                </div>
                                <div
                                  className={classNames(
                                    active ? "border" : "border-2",
                                    checked
                                      ? "border-indigo-500"
                                      : "border-transparent",
                                    "absolute -inset-px rounded-lg pointer-events-none"
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="sm:flex">
                    {this.state.addModalProcessing ? (
                      "Processing..."
                    ) : (
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mr-3 sm:w-auto sm:text-sm"
                        onClick={this.runAddModal.bind(this)}
                      >
                        Link
                      </button>
                    )}

                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:mr-3 sm:w-auto sm:text-sm"
                      onClick={() => {
                        this.setState({ addModalOpen: false });
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>

        <div className="mt-8 mb-2 lg:mt-0">
          <div className="flex flex-row items-center">
            {/* <button
              onClick={this.refreshData}
              className="cursor-pointer inline-flex items-center justify-center w-8 h-8 border border-transparent text-base shadow-lg m-2 font-medium rounded-full text-white bg-white hover:bg-gray-100"
            >
              <RefreshIcon className="h-4 w-4 text-gray-500" />
            </button>
            <button
              onClick={() => {
                // TODO: download functionality
              }}
              className="cursor-pointer inline-flex items-center justify-center w-8 h-8 border border-transparent text-base shadow-lg m-2 mfont-medium rounded-full text-white bg-white hover:bg-gray-100"
            >
              <DocumentDownloadIcon className="h-4 w-4 text-gray-500" />
            </button> */}
            <h2
              className="text-lg font-semibold"
              onClick={() => {
                navigator.clipboard.writeText(this.state.logbook.id);
                alert(
                  "Copied " +
                    this.state.logbook.data().name +
                    "'s ID (" +
                    this.state.logbook.id +
                    ") to your clipboard."
                );
              }}
            >
              {this.state.logbook
                ? this.state.logbook.data().name
                : "Loading logbook..."}
            </h2>
          </div>
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg px-8">
              {this.state.queryResult ? (
                // check if query result has any data
                this.state.queryResult.length > 0 ? (
                  <div>
                    <ul className="divide-y divide-gray-200">
                      {this.state.queryResult.map((event) => (
                        <li key={event.id} className="py-4">
                          <div className="flex space-x-3">
                            {event.identity.data.photo ? (
                              <>
                                <Link to={"/app/people/" + event.identity.id}>
                                  <img
                                    className="h-10 w-10 rounded-full object-cover"
                                    src={
                                      "https://storage.googleapis.com/uplink-services-roster-photos/" +
                                      event.identity.data.photo
                                    }
                                    alt={
                                      event.identity.data.firstName +
                                      " " +
                                      event.identity.data.lastName
                                    }
                                  />
                                </Link>
                                <div className="flex-1 space-y-1">
                                  <div className="flex items-center justify-between">
                                    <Link
                                      to={"/app/people/" + event.identity.id}
                                      className="text-sm leading-5 font-medium text-gray-900"
                                    >
                                      <h3 className="text-sm font-medium">
                                        {event.identity.data.firstName}{" "}
                                        {event.identity.data.lastName}
                                      </h3>
                                    </Link>
                                    <p className="text-sm text-gray-500">
                                      {
                                        <ReactTimeAgo
                                          date={event.data.timestamp.toDate()}
                                          locale="en-US"
                                        />
                                      }
                                    </p>
                                  </div>
                                  <p className="text-sm text-gray-500">
                                    Checked in to {event.source.data.name}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <>
                                <span className="h-10 w-10">
                                  <button
                                    type="button"
                                    className="h-10 w-10 inline-flex items-center justify-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={this.openAddModal.bind(
                                      this,
                                      event.data.data
                                    )}
                                  >
                                    <UserAddIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </span>
                                <div className="flex-1 space-y-1">
                                  <div className="flex items-center justify-between">
                                    <button
                                      onClick={this.openAddModal.bind(
                                        this,
                                        event.data.data
                                      )}
                                    >
                                      <h3 className="text-sm font-medium">
                                        Unenrolled student
                                      </h3>
                                    </button>
                                    <p className="text-sm text-gray-500">
                                      {
                                        <ReactTimeAgo
                                          date={event.data.timestamp.toDate()}
                                          locale="en-US"
                                        />
                                      }
                                    </p>
                                  </div>
                                  <p className="text-sm text-gray-500">
                                    Checked in to {event.source.data.name}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p className="my-4 text-sm text-gray-500">
                    This logbook is empty.
                  </p>
                )
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Table;
