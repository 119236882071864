import { compareDesc } from "date-fns";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import React from "react";
import ReactTimeAgo from "react-time-ago";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { db } from "../firebase.js";

class PersonInfoView extends React.Component {
  constructor() {
    super();
    this.state = {
      identity: null,
      events: null,
    };
  }

  render() {
    if (this.state.identity) {
      let identity = this.state.identity;
      return (
        <SimpleReactLightbox>
          <div className="bg-white shadow overflow-hidden sm:rounded-lg w-full">
            <div className="px-4 py-4 sm:px-6">
              <div className="flex">
                <div className="flex-shrink-0 h-20 w-20">
                  <SRLWrapper
                    options={{
                      settings: {
                        lightboxTransitionSpeed: 0.1,
                      },
                      buttons: {
                        showAutoplayButton: false,
                        showCloseButton: true,
                        showDownloadButton: true,
                        showFullscreenButton: false,
                        showNextButton: false,
                        showPrevButton: false,
                        showThumbnailsButton: false,
                      },
                      thumbnails: {
                        showThumbnails: false,
                      },
                      caption: {
                        showCaption: false,
                      },
                    }}
                  >
                    <a
                      href={
                        "https://storage.googleapis.com/uplink-services-roster-photos/" +
                        identity.data().photo
                      }
                    >
                      <img
                        className="h-20 w-20 rounded-md object-cover"
                        src={
                          "https://storage.googleapis.com/uplink-services-roster-photos/" +
                          identity.data().photo
                        }
                        alt={
                          identity.data().firstName +
                          " " +
                          identity.data().lastName
                        }
                      />
                    </a>
                  </SRLWrapper>
                </div>
                <div className="ml-4">
                  <h3 className="text-xl leading-6 font-medium text-gray-900">
                    {identity
                      ? identity.data().firstName +
                        " " +
                        identity.data().lastName
                      : null}
                  </h3>
                  <p className="max-w-2xl text-sm text-gray-500">
                    {(() => {
                      switch (identity.data().grade) {
                        case 9:
                          return "Freshman";
                        case 10:
                          return "Sophomore";
                        case 11:
                          return "Junior";
                        case 12:
                          return "Senior";
                        default:
                          return "Unknown";
                      }
                    })()}
                  </p>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Student number
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {identity.data().studentNumber}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Ways to check in
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <ul className="space-y-4">
                      {this.state.cards
                        ? this.state.cards.map((card) => (
                            <li key={card.id} className="relative bg-white">
                              <div className="flex justify-between space-x-3">
                                <div className="min-w-0 flex-1">
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  />
                                  <p className="text-sm font-medium text-gray-900 truncate">
                                    {(() => {
                                      switch (card.data().type) {
                                        case "wahs-badge":
                                          return "Student ID issued by WAHS";
                                        case "sticker":
                                          return "Manually enrolled sticker";
                                        default:
                                          return "Unknown method";
                                      }
                                    })()}
                                  </p>
                                  <p className="text-sm text-gray-500 truncate">
                                    {card.id}
                                  </p>
                                </div>
                                <time
                                  dateTime={
                                    card.data().issueDate
                                      ? card
                                          .data()
                                          .issueDate.toDate()
                                          .toISOString()
                                      : null
                                  }
                                  className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500"
                                >
                                  {card.data().issueDate ? (
                                    <>
                                      {" "}
                                      <p>
                                        Issued{" "}
                                        <ReactTimeAgo
                                          date={card.data().issueDate.toDate()}
                                          locale="en-US"
                                        />{" "}
                                      </p>{" "}
                                    </>
                                  ) : (
                                    "Unknown issue date"
                                  )}
                                </time>
                              </div>
                              {card.data().note ? (
                                <div className="mt-1">
                                  <p className="line-clamp-2 text-sm text-gray-600">
                                    {card.data().note}
                                  </p>
                                </div>
                              ) : null}
                            </li>
                          ))
                        : null}
                    </ul>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Recent scans
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <ul className="space-y-2">
                      {this.state.events
                        ? this.state.events.map((event) =>
                            this.state.logbooks.find(
                              (logbook) =>
                                logbook.id === event.data().logbook.id
                            ) ? (
                              <li key={event.id} className="relative bg-white">
                                <div className="flex justify-between space-x-3">
                                  <div className="min-w-0 flex-1">
                                    <span
                                      className="absolute inset-0"
                                      aria-hidden="true"
                                    />
                                    <p className="text-sm font-medium text-gray-900 truncate">
                                      {
                                        this.state.logbooks
                                          .find(
                                            (logbook) =>
                                              logbook.id ===
                                              event.data().logbook.id
                                          )
                                          .data().name
                                      }
                                    </p>
                                  </div>
                                  <time
                                    dateTime={event
                                      .data()
                                      .timestamp.toDate()
                                      .toISOString()}
                                    className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500"
                                  >
                                    <ReactTimeAgo
                                      date={event.data().timestamp.toDate()}
                                      locale="en-US"
                                    />
                                  </time>
                                </div>
                              </li>
                            ) : null
                          )
                        : null}
                    </ul>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </SimpleReactLightbox>
      );
    } else {
      return <div>Loading...</div>;
    }
  }

  componentDidMount() {
    getDoc(doc(db, "identities", this.props.id)).then((doc) => {
      if (doc) {
        this.setState({
          identity: doc,
        });
        getDocs(
          query(collection(db, "cards"), where("identity", "==", doc.ref))
        ).then((cards) => {
          if (cards.docs.length > 0) {
            this.setState({
              cards: cards.docs,
            });
            let events = [];
            let logbooks = [];
            cards.docs.forEach((card) => {
              var promisedEvents = [];
              var promisedLogbooks = [];
              promisedEvents.push(
                getDocs(
                  query(collection(db, "events"), where("data", "==", card.id))
                ).then((eventResults) => {
                  eventResults.docs.forEach((event) => {
                    events.push(event);
                    promisedLogbooks.push(
                      getDoc(event.data().logbook).then((logbook) => {
                        logbooks.push(logbook);
                      })
                    );
                  });
                })
              );

              Promise.all(promisedEvents).then(() => {
                Promise.all(promisedLogbooks).then(() => {
                  this.setState({
                    events: events.sort((a, b) => {
                      return compareDesc(
                        a.data().timestamp.toDate(),
                        b.data().timestamp.toDate()
                      );
                    }),
                    logbooks: logbooks,
                  });
                });
              });
            });
          }
        });
      }
    });
  }
}

export default PersonInfoView;
