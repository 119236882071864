import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={
          this.props.marketing ? "bg-gray-50" : "bg-white dark:bg-black"
        }
      >
        <div className="max-w-7xl mx-auto py-2 px-4 sm:px-4 lg:py-8 lg:px-8">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; 2022 Uplink. All rights reserved.
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
