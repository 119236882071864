/* This example requires Tailwind CSS v2.0+ */

/* const steps = [
  { name: "Create account", status: "complete" },
  { name: "Set up profile", status: "current" },
  { name: "Add a sensor", status: "upcoming" },
  { name: "Create a logbook", status: "upcoming" },
  { name: "Scan an ID", status: "upcoming" },
]; */

export default function Steps() {
  return {
    /* <nav className="flex" aria-label="Progress">
      <ol className="space-y-6">
        {steps.map((step) => (
          <li key={step.name}>
            {step.status === "complete" ? (
              <a className="group">
                <span className="flex items-start">
                  <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                    <CheckCircleIcon
                      className="h-full w-full text-indigo-600 "
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-3 text-sm font-medium text-gray-500 ">
                    {step.name}
                  </span>
                </span>
              </a>
            ) : step.status === "current" ? (
              <a className="flex items-start" aria-current="step">
                <span
                  className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                  aria-hidden="true"
                >
                  <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                  <span className="relative block w-2 h-2 bg-indigo-600 rounded-full" />
                </span>
                <span className="ml-3 text-sm font-medium text-indigo-600">
                  {step.name}
                </span>
              </a>
            ) : (
              <a className="group">
                <div className="flex items-start">
                  <div
                    className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                    aria-hidden="true"
                  >
                    <div className="h-2 w-2 bg-gray-300 rounded-full " />
                  </div>
                  <p className="ml-3 text-sm font-medium text-gray-500 ">
                    {step.name}
                  </p>
                </div>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav> */
  };
}
