import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import UplinkIcon600 from "./assets/uplink-icon-600.svg";
import AppShell from "./components/AppShell";
import ErrorBoundary from "./components/ErrorBoundary";
import FeatureSection from "./components/FeatureSection";
import Footer from "./components/Footer";
import HeroSection from "./components/HeroSection";
import IdentitiesTable from "./components/IdentitiesTable";
import LogbooksTable from "./components/LogbooksTable";
import PersonInfoView from "./components/PersonInfoView";
import Pricing from "./components/Pricing";
import SensorsTable from "./components/SensorsTable";
import Steps from "./components/Steps";
import Table from "./components/Table";
import { auth } from "./firebase.js";

Sentry.init({
  dsn: "https://3f24c75adbb94838b461413f7384f8c5@o201925.ingest.sentry.io/6123755",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: "uplink-app@" + process.env.REACT_APP_VERSION,
  environment: process.env.NODE_ENV,
});

auth.onAuthStateChanged((user) => {
  if (user) {
    Sentry.setUser({ email: user.email });
  } else {
    Sentry.configureScope((scope) => scope.setUser(null));
  }
});

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: "popup",
  signInSuccessUrl: "/app",
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      buttonColor: "white",
    },
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
    this.logout = this.logout.bind(this);
  }
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user: user });
      }
    });
  }
  logout() {
    auth.signOut().then((result) => {
      this.setState({
        user: null,
      });
    });
  }
  render() {
    return (
      <>
        <Routes>
          <Route
            path="/"
            element={
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <HeroSection />
                      <FeatureSection />
                      <Footer marketing />
                    </>
                  }
                />
              </Routes>
            }
          />
          <Route
            path="/pricing"
            element={
              <>
                <Pricing />
              </>
            }
          />
          <Route
            path="/login"
            element={!this.state.user ? <Login /> : <Navigate to="/app/" />}
          />
          <Route
            path="/app/*"
            element={
              !this.state.user ? (
                <Login />
              ) : (
                <>
                  <Routes>
                    <>
                      <Route
                        path="/"
                        element={<Navigate to="/app/logbooks" />}
                      />
                      <Route path="/onboarding" element={<Onboarding />} />
                      <Route path="/logbooks">
                        <Route index element={<Logbooks />} />
                        <Route path=":id" element={<Logbook />} />
                      </Route>
                      <Route path="/people">
                        <Route index element={<People />} />
                        <Route path=":id" element={<Person />} />
                      </Route>
                      <Route path="/sensors" element={<Sensors />} />
                    </>
                  </Routes>
                </>
              )
            }
          />
        </Routes>
      </>
    );
  }
}

/* function Home(logout) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Uplink | Home</title>
        <link rel="canonical" href="https://uplink.westashleyrobotics.com/" />
      </Helmet>
      <AppShell header="Your dashboard" logout={logout}>
        <div className="w-full">
          <div className="h-96 border-4 border-dashed border-gray-200 rounded-lg text-center flex content-center justify-center flex-col">
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              Dashboard under construction
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              This feature isn't available yet.
            </p>
          </div>
        </div>
      </AppShell>
    </>
  );
}
 */
function Onboarding() {
  return (
    <>
      <AppShell header="Get started with Uplink">
        <div className="w-1/4">{Steps()}</div>
        <div className="w-full">
          <div className="h-96 border-4 border-dashed border-gray-200 rounded-lg" />
        </div>
      </AppShell>
    </>
  );
}

function Logbooks() {
  return (
    <>
      <AppShell header="Your logbooks">
        <LogbooksTable />
      </AppShell>
    </>
  );
}
function Logbook() {
  let params = useParams();

  return (
    <>
      <AppShell header="View logbook">
        <Table logbook={params.id} />
      </AppShell>
    </>
  );
}

function People() {
  return (
    <>
      <AppShell header="Manage people">
        <ErrorBoundary>
          <IdentitiesTable />
        </ErrorBoundary>
      </AppShell>
    </>
  );
}
function Person() {
  let params = useParams();

  return (
    <>
      <AppShell header="View person">
        <ErrorBoundary>
          <PersonInfoView id={params.id} />
        </ErrorBoundary>
      </AppShell>
    </>
  );
}
/* 
function SerialMonitorPage() {
  return (
    <>
      <AppShell header="Monitor a serial port">
        <ErrorBoundary>
          <SerialMonitor />
        </ErrorBoundary>
      </AppShell>
    </>
  );
} */

function Sensors() {
  return (
    <>
      <AppShell header="Manage sensors">
        <SensorsTable />
        {/* <div className="w-full">
          <div className="h-96 border-4 border-dashed border-gray-200 rounded-lg text-center flex content-center justify-center flex-col">
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No sensors
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by setting up a sensor.
            </p>
          </div>
        </div> */}
      </AppShell>
    </>
  );
}

function Login() {
  return (
    <>
      <>
        <div className="h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 dark:bg-black">
          <div className="max-w-md w-full space-y-8">
            <div>
              <img
                className="mx-auto h-16 w-auto"
                src={UplinkIcon600}
                alt="Uplink"
              />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">
                Sign in to Uplink
              </h2>
            </div>
            <div className="rounded-xl overflow-hidden shadow-lg bg-white dark:bg-neutral-800">
              <div className="px-6 py-4">
                {!auth.currentUser ? (
                  <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
                ) : (
                  <p>
                    You're signed in as {auth.currentUser.displayName} (
                    {auth.currentUser.email}).
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
export default App;
