import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAgBIoeGF1GL1HzGoww4Zka1efkuj6bSoo",
  authDomain: "uplink.westashleyrobotics.com",
  projectId: "uplink-services",
  storageBucket: "uplink-services.appspot.com",
  messagingSenderId: "550423205574",
  appId: "1:550423205574:web:23afaa5c773e4056b369c7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export {app, auth, db}