import { ClipboardCopyIcon } from "@heroicons/react/solid";
import {
  collection,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import React from "react";
import { FixedSizeList } from "react-window";
import { db } from "../firebase.js";

class SensorsRow extends React.Component {
  render() {
    let sensor = this.props.data[this.props.index];
    return (
      <div className="border-b border-gray-200" style={this.props.style}>
        <div className="px-6 py-2 whitespace-nowrap">
          <div className="flex items-center justify-between">
            <div>
              <div className="text-sm font-medium text-gray-900">
                {sensor.data.name}
              </div>
              <div className="text-sm text-gray-500">
                Currently writing to {sensor.assignedLogbook.name}
              </div>
            </div>
            <ClipboardCopyIcon
              onClick={() => {
                navigator.clipboard.writeText(sensor.data.key);
                alert(
                  "Copied " +
                    sensor.data.name +
                    "'s API key (" +
                    sensor.data.key +
                    ") to your clipboard."
                );
              }}
              className="h-6 w-6 text-gray-500 cursor-pointer"
            />
          </div>
        </div>
      </div>
    );
  }
}

class SensorsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryResult: null,
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData = () => {
    const q = query(
      collection(db, "sensors"),
      orderBy("name", "desc"),
      limit(2000)
    );

    var resolvedData = [];

    getDocs(q).then((sensors) => {
      sensors.docs.forEach((sensor) => {
        resolvedData.push(
          getDoc(sensor.data().assignedLogbook).then((logbook) => {
            return {
              ref: sensor.ref,
              id: sensor.id,
              data: { ...sensor.data() },
              assignedLogbook: { id: logbook.id, name: logbook.data().name },
            };
          })
        );
      });

      Promise.all(resolvedData).then((values) => {
        this.setState({
          queryResult: values,
        });
      });
    });
  };

  render() {
    return (
      <div className="flex flex-col mx-2 w-full">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            {this.state.queryResult ? (
              <FixedSizeList
                itemData={this.state.queryResult}
                height={200}
                itemCount={this.state.queryResult.length}
                itemSize={56}
              >
                {SensorsRow}
              </FixedSizeList>
            ) : (
              <div className="text-center my-3">
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  Loading sensors...
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SensorsTable;
